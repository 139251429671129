import React from 'react'
import "./Menus.scss"
import { Link } from "react-router-dom"
import { Image } from 'react-bootstrap'
import linkedin from "../../../img/landing/linkedin.svg"
import youtube from "../../../img/landing/youtube.svg"
import instagram from "../../../img/landing/instagram.svg"
import logoblack from "../../../img/landing/logo-black.svg"
// GOOGLE ANALYTICS
import ReactGA from 'react-ga'

export class Menus extends React.Component {

    contentFooterLink = () => {
        return (
            <div className="landingpage-footer-content menu">
                <div className="footer-content-teks-menu">
                    <h5>Bahasa Kita</h5>
                    <p>
                        <Link to="/about">
                            Tentang Kami
                        </Link>
                    </p>
                    <p>
                        <Link to="/contact">
                            Kontak Kami
                        </Link>
                    </p>
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Rapat</h5>
                    {/*<p>
                        <a
                            eventLabel="minit"
                            href={window.link.minit}
                            target="_blank"
                        >
                            Minit
                        </a>
                        
                    </p>*/}
                    <p>
                        <a
                            eventLabel="notulite"
                            href={window.link.notulite}
                            target="_blank"
                        >
                            Notulite
                        </a>
                    </p>                    
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Gaya Hidup</h5>
                        <p>
                            <Link to="/tman">T-Man (Prototipe)</Link>
                        </p>
                    
                        <p>
                            <Link to="/smartspeaker">
                                Smart Speaker (Prototipe)
                            </Link>
                        </p>
                    
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Layanan</h5>
                    <p>
                        <a
                            eventLabel="diktein"
                            href={window.link.diktein}
                            target="_blank"
                        >
                            Diktein
                        </a>
                    </p>
                    {/*<p>
                        <a
                            eventLabel="tts"
                            href={window.link.tts}
                            target="_blank"
                        >
                            Text to Speech                            
                        </a>
                    </p>
                    <p>
                        <a
                            eventLabel="stt"
                            href={window.link.stt}
                            target="_blank"
                        >
                            Speech to Text
                        </a>
                    </p>*/}
                </div>
                <div className="footer-content-teks-menu">
                    <h5>Ikuti Kami</h5>
                    <p>
                        <Image src={instagram} alt="" />
                        <a
                            eventLabel="instagram"
                            href={window.link.instagram}
                            target="_blank"
                        >
                            Instagram
                        </a>
                        
                    </p>
                    <p>
                        <Image src={linkedin} alt="" />
                        <a
                            eventLabel="linkedin"
                            href={window.link.linkedin}
                            target="_blank"
                        >
                            LinkedIn
                        </a>
                       
                    </p>
                    <p>
                        <Image src={youtube} alt="" />
                        <a
                            eventLabel="youtube"
                            href={window.link.youtube}
                            target="_blank"
                        >
                            Youtube
                        </a>
                       
                    </p>
                </div>
            </div>
        )
    }

    contentFooterSosmed = () => {
        const getCurrentYear = () => { return new Date().getFullYear();};

        return (
            <div className="landingpage-footer-sosmed">                
                <p>© 2015 - {getCurrentYear()} <img src={logoblack} alt=""/> PT. Bahasa Kinerja Utama. All right reserved.</p>
            </div>
        )
    }

    render() {
        return (
            <div id="landingpage-footer">
                {this.contentFooterLink()}
                {this.contentFooterSosmed()}
            </div>
        )
    }
}

export default Menus
