import React, { useState } from 'react'
import logo from "../../img/landing/logo.svg"
import { Link } from 'react-router-dom'
import { Button, Navbar, Image } from 'react-bootstrap'
import DropdownMenu from "./DropdownMenu"
import "./Navbar.scss"

import ReactGA from 'react-ga'

const NavbarService = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(prevShow => !prevShow);
    }

    return (
        <Navbar collapseOnSelect expand="md" id="navbar-main">
            <Navbar.Brand id="navbar-logo">
                <Link to="/" onClick={props.onCLick}>
                    <Image src={logo} />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleShow}/>
            <div className={`navbar-collapse collapse ${show ? "show" : ""}`} id="responsive-navbar-nav">
                <DropdownMenu handleShow={handleShow} />
                {/* <Link
                    to="/about"
                    className="btn-navbar-kontak-kami tentang-kami">
                    Tentang Kami
                </Link> */}
                <Link
                    to="/contact"
                >
                    <Button
                        className="btn-navbar-kontak-kami blue"
                        id="navbarlandingpagemain-kontakkami">
                        Kontak Kami
                    </Button>
                </Link>
                {/*<ReactGA.OutboundLink eventLabel="web-console" to={window.link.webconsole}
                    target="_blank" rel="noopener noreferrer">              
                    <Button
                        className="btn-navbar-kontak-kami transparant"
                        id="navbarlandingpagemain-services">
                        Go to Services
                    </Button>
            </ReactGA.OutboundLink>*/}
            </div>
            <div className={`backdrop ${show ? "show" : ""}`} onClick={handleShow}/>
        </Navbar>
    )
}

export default NavbarService
