import React, { Component } from 'react'
import { Image, Button, Row, Col } from 'react-bootstrap'
import "./Product.scss"

// import { Link } from 'react-router-dom'

import NavbarMain from '../../components/navbar/NavbarMain'

import landing1 from "../../img/product/smartspeaker1.png"
import landing2 from "../../img/product/smartspeaker2.png"
import bubble from "../../img/product/bubble.svg"
import itemmenu1 from "../../img/product/itemmenu1.svg"
import itemmenu2 from "../../img/product/itemmenu2.svg"
import itemmenu3 from "../../img/product/itemmenu3.svg"
import itemmenu4 from "../../img/product/itemmenu4.svg"
import kontakkami from "../../img/landing/kontakkami.png"

import Fade from 'react-reveal/Fade'
import ContactUs from '../../components/Footer/ContactUs/ContactUs'
import Menus from '../../components/Footer/Menus/Menus'

export class SmartSpeaker extends Component {
    componentDidMount() {
        document.title = "Bahasa Kita | Smart Speaker"
        // document.getElementById('product-content-top').focus();
        window.scrollTo(0,0)
    }

    
    scrollToElement = () => {
        const yOffset = -120; 
        const element = document.getElementById("contentFeature");
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({showMenu:false})
    }


    // Content Rendered
    contentTopLanding = () => {
        return (
            <div tabIndex="0" id="product-content-top" className="product-content-wrapper">
                <Fade>
                    <div className="product-content-paragraf">
                        <h1>
                            Memberikan arti baru untuk rumah.
                        </h1>
                        <p>
                            Smart Speaker membantu dan memberikan kemudahan untuk Anda di rumah.
                        </p>
                        <button onClick={()=>this.scrollToElement()}>
                            Cari tahu
                        </button>
                    </div>
                    <div className="product-content-rectangle-1"></div>
                    <div className="product-content-image-wrapper right">
                        <Image src={landing1} />
                    </div>
                    <div className="product-content-bubble">
                        <Image src={bubble} />
                    </div>
                </Fade>
            </div>
        )
    }

    contentFeature = () => {
        return (                                            
                <div id="product-content-top" className="product-content-wrapper">
                    <Fade>
                        <div className="product-content-rectangle-3"></div>
                        <div className="product-content-image-wrapper" id="contentFeature">
                            <Image src={landing2}/>                        
                        </div>
                        <div className="product-content-paragraf medium">
                            <h2>
                                Kontrol perangkat rumah dengan suara
                            </h2>
                            <p className="s">
                                Smart speaker dapat mengerti perintah melalui suara dalam Bahasa Indonesia.
                            </p>
                        </div>
                    </Fade>
                </div>
        )
    }
    
    contentBottomLanding = () => {
        return (
            <div id="content-product-menu">
                <Fade>
                <h2>
                    Fitur Smart Speaker
                </h2>
                <h5>
                Smart Speaker memiliki fitur fitur menarik kamu sekeluarga 
                </h5>
                </Fade>
                <Fade>
                <div className="content-product-menu">
                    <div className="content-product-menu-item">
                        <Image src={itemmenu1} />
                        <h3>Call Center</h3>
                        <p>
                            Automasi call center dan asisten virtual untuk perusahaan
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu2} />
                        <h3>IoT Control</h3>
                        <p>
                            Optimasisasi penggunaan Internet of Things untuk contorl perangkat menggunakan suara.
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu3} />
                        <h3>Narator</h3>
                        <p>
                            Sistem narator untuk penyandang disabilitas yang 
                            membantu mereka untuk lorem ipsum dolor.
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu4} />
                        <h3>Statistical Language </h3>
                        <p>
                            Optimasisasi penggunaan Internet of Things 
                            untuk contorl perangkat menggunakan suara.
                        </p>
                    </div>
                </div>
                </Fade>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarMain />
                <div id="landing-page-product">
                    {this.contentTopLanding()}
                    {this.contentFeature()}
                </div>
                {/* <div className="landing-bottom-page-product">
                    {this.contentBottomLanding()}
                </div>                 */}
                
                {/* footer */}
                <ContactUs />
                <Menus />

            </React.Fragment>
        )
    }
}

export default SmartSpeaker
