import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTestimonyList } from '../../reducers/ServiceReducers'

import './Testimony.scss'
import cright from '../../img/landing/c-right.svg'
import cleft from '../../img/landing/c-left.svg'
import quote from '../../img/landing/quote.svg'

function Testimony(props) {
    const { type } = props;
    
    const dispatch          = useDispatch();
    const testimony_data     = useSelector(state => state.ServiceReducers.testimony_data)

    const [testimony_list, setTestimony_list] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        dispatch(fetchTestimonyList(type, 0, 20))
    }, []);
    
    useEffect(() => {
        if ( 
            testimony_data.status   === "success" && 
            testimony_data.type     === type 
        ) {
            setTestimony_list(testimony_data.data)
        }
    }, [testimony_data]); // Only re-run the effect if testimony_data changes

    function handleBtnRight() {
        setSelectedIndex(selectedIndex+1 === testimony_list.length ? selectedIndex : selectedIndex+1);
        const total = 538+selectedIndex*538;
        const element = document.getElementById("testi-wrapper");
        element.scrollTo(total, 0)
    }
    function handleBtnLeft() {
        setSelectedIndex(selectedIndex !== 0 && selectedIndex+1 <= testimony_list.length ? selectedIndex-1 : selectedIndex);
        const total = (selectedIndex-1)*538;
        const element = document.getElementById("testi-wrapper");
        element.scrollTo(total, 0)
    }

    return (
        <div className="testimony-container">
            <div className="testimony-head-wrap">
                <h2 className="head">Apa kata mereka</h2>
                <p className="sub-head">Kesan dari para pengguna teknologi Bahasa Kita.</p>
                <div className="testimony-btn-wrap">
                    <img src={cleft} alt="icon-btn" className="icon-img-btn" onClick={handleBtnLeft}/>
                    <img src={cright} alt="icon-btn" className="icon-img-btn" onClick={handleBtnRight}/>
                </div>
            </div>
            <div className="testimony-wrapper" id="testi-wrapper">
                { testimony_list.map((item, i) => {
                    return (
                        <TestiCard 
                            key={item.name+i}
                            title={item.title}
                            statement={item.statement}
                            avatar={item.img}
                            name={item.name}
                            position={item.position}
                            company={item.company}
                            active={selectedIndex === i ? true : false}
                            handleClick={()=>setSelectedIndex(i)}
                            id={"id"+i}
                        />
                    )
                }) }
            </div>
        </div>
    )
}

function TestiCard(props) {
    return (
        <div className={props.active ? "testi-card-item":"testi-card-item disabled"} onClick={props.handleClick} id={props.id}>
            <img src={quote} alt="icon" id="quote-icon"/>
            <h4>{props.title}</h4>
            <p>{props.statement}</p>
            <div className="testi-id">
                <img src={props.avatar} alt="ava" id="avatar"/>
                <h5 id="id-name">{props.name}
                    <span>{props.position} {props.company}</span>
                </h5>
            </div>
        </div>
    )
}

export default Testimony;