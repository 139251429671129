import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import styled from 'styled-components';

const PlcHolder = styled.img`
  background: linear-gradient(
    to right,
    rgb(246, 247, 248) 0%,
    rgb(237, 238, 241) 20%,
    rgb(246, 247, 248) 40%,
    rgb(246, 247, 248) 100%
  );
  @keyframes placeHolderShimmer {
    0% {
      background-position: top right;
    }
    100% {
      background-position: top left;
    }
  }
  width: 100%;
  height: 100%;
  background-size: 2250px 2250px !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  animation: placeHolderShimmer 1.5s ease-in-out infinite;
`;
const ImageParent = styled.div`
  position: relative;
  width: 100%;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export default ({
  width = 414,
  height = 184,
  src,
  placeholder,
  className = '',
  alt = 'Image',
}) => {
    var style = {};
    width ? style.width=width : style=style;
    height ? style.height=height : style=style;
    
    return (
        <ImageParent>
            <ProgressiveImage
                src={src}
                placeholder={placeholder}
            >
                {(src, loading) => {
                    return (
                        <div>
                            { !placeholder ? 
                                ( loading ? 
                                    <PlcHolder
                                        className={className}
                                        src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'200' height%3D'150' viewBox%3D'0 0 200 150'%2F%3E"
                                        // w={width}
                                        // h={height}
                                        style={style}
                                    /> 
                                    : 
                                    <StyledImage
                                        className={className}
                                        src={src}
                                        alt={alt}
                                    />
                                ) 
                                : 
                                <StyledImage
                                    className={className}
                                    style={{ filter: loading ? 'blur(3px)' : 'blur(0px)' }}
                                    src={src}
                                    alt={alt}
                                />
                            }
                            <noscript>
                                <img className={className + " progressive-image no-script"} src={src} />
                            </noscript>
                        </div>
                    )
                }}
            </ProgressiveImage>
        </ImageParent>
    );
};