import React, { Component } from 'react'
import { Image, Button, Row, Col } from 'react-bootstrap'
import "./Product.scss"

// import { Link } from 'react-router-dom'

import NavbarMain from '../../components/navbar/NavbarMain'

import landing1 from "../../img/product/landing1.png"
import landing2 from "../../img/product/TTS.png"
import bubble from "../../img/product/bubble.svg"
import itemmenu1 from "../../img/product/itemmenu1.svg"
import itemmenu2 from "../../img/product/itemmenu2.svg"
import itemmenu3 from "../../img/product/itemmenu3.svg"
import itemmenu4 from "../../img/product/itemmenu4.svg"
import kontakkami from "../../img/landing/kontakkami.png"

import Fade from 'react-reveal/Fade'
import ContactUs from '../../components/Footer/ContactUs/ContactUs'
import Menus from '../../components/Footer/Menus/Menus'

export class NLP extends Component {
    componentDidMount() {
        document.title = "Bahasa Kita | NLP"
        window.scrollTo(0,0)
        // document.getElementById('product-content-top').focus();
    }

    
    scrollToElement = () => {
        const yOffset = -120; 
        const element = document.getElementById("contentFeature");
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({showMenu:false})
    }

    // Content Rendered
    contentTopLanding = () => {
        return (
            <div id="product-content-top" className="product-content-wrapper">
                <div className="product-content-paragraf">
                    <h1>
                         AI Solution for Indonesia
                    </h1>
                    <p>
                    Gain deeper insight and understanding of the Indonesian audiences by utilizing the power of Artificial Intelligence crafted by local experts
                    </p>
                    <button onClick={()=>this.scrollToElement()}>
                        GET STARTED
                    </button>
                </div>
                <div className="product-content-image-wrapper">
                    <Image src={landing1} />
                </div>
                <div className="product-content-bubble">
                    <Image src={bubble} />
                </div>
            </div>
        )
    }

    contentFeature = () => {
        return (                                            
                <div id="product-content-top" className="product-content-wrapper">
                    <div className="product-content-image-wrapper" id="contentFeature">
                        <Image src={landing2} />
                    </div>
                    <div className="product-content-paragraf medium">
                        <h2>
                            Reinventing how people connect with technology and each other
                        </h2>
                        <p className="s">
                        Gain deeper insight and understanding of the Indonesian audiences by utilizing the power of Artificial Intelligence crafted by local experts
                        </p>
                    </div>
                </div>
        )
    }
    
    contentBottomLanding = () => {
        return (
            <div id="content-product-menu">
                <h2>
                    Natural Language Processing
                </h2>
                <h5>
                    NLP Memiliki  fitur fitur menarik untuk perkembangan teknologi masa depan 
                </h5>
                <div className="content-product-menu">
                    <div className="content-product-menu-item">
                        <Image src={itemmenu1} />
                        <h3>Data Acquisition</h3>
                        <p>
                            Automasi call center dan asisten virtual untuk perusahaan
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu2} />
                        <h3>RAW Language Processing</h3>
                        <p>
                            Optimasisasi penggunaan Internet of Things untuk contorl perangkat menggunakan suara.
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu3} />
                        <h3>Query Understanding</h3>
                        <p>
                            Sistem narator untuk penyandang disabilitas yang 
                            membantu mereka untuk lorem ipsum dolor.
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu4} />
                        <h3>Statistical Language </h3>
                        <p>
                            Optimasisasi penggunaan Internet of Things 
                            untuk contorl perangkat menggunakan suara.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    
    render() {
        return (
            <React.Fragment>
                <NavbarMain />
                <div id="landing-page-product">
                    <Fade>{this.contentTopLanding()}</Fade>
                    <Fade>{this.contentFeature()}</Fade>
                </div>
                <div className="landing-bottom-page-product">
                    <Fade>{this.contentBottomLanding()}</Fade>
                </div>
                
                {/* footer */}
                <ContactUs />
                <Menus />

            </React.Fragment>
        )
    }
}

export default NLP
