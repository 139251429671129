import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, Row, Col, Button } from 'react-bootstrap';
import CustomImage from '../../../components/CustomImage';
import NavbarMain from '../../../components/navbar/NavbarMain'
import Menus from '../../../components/Footer/Menus/Menus';
import ContactUs from '../../../components/Footer/ContactUs/ContactUs';

import './Notulite.scss';

import notulite1 from "../../../img/landing/notulite1.png";
import notulite2 from "../../../img/landing/notulite2.png";
import notulite3 from "../../../img/landing/notulite3.png";
import notulite4 from "../../../img/landing/notulite4.png";
import play from "../../../img/landing/play.svg";


export const ModalVideo = (props) => {
    return (
        <Modal
            show={props.show}
            centered
            onHide={props.setCloseModal}
        >
            <iframe
                title="Alur Penggunaan"
                src="https://www.youtube.com/embed/fgGItGY6vuI"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>

            </iframe>
        </Modal>
    )
}

export class Notulite extends Component {
    state = {
        show: false
    }

    setCloseModal = () => {
        this.setState({ show: false })
    }

    scrollToElement = () => {
        const yOffset = -120; 
        const element = document.getElementById("demo-video-container");
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({showMenu:false})
    }

    contentTopLanding = () => {
        return (
            <div id="landingpage-content-top" className="content-wrapper animated fadeIn">
                <div className="content-paragraf left">
                    <h1>
                        Rapat Anda akan di transkripsi dengan mudah
                    </h1>
                    <p>
                        Notulite dapat menjadi solusi untuk berbagai kebutuhan transkripsi anda. <br />Portable dan mudah penggunaanya.
                    </p>
                    <Button className="btn-landing blue-2 mt-3" onClick={()=>this.scrollToElement()}>
                        Cari tahu
                    </Button>
                </div>
                <div className="content-image-wrapper">
                    {/* <img src={notulite1} /> */}
                    <CustomImage
                        src={notulite1}
                        // placeholder={lowRes}
                        alt="Content Cover"
                    />
                </div>
            </div>
        )
    }

    contentTopLandingVideo = () => {
        return (
            <div id="demo-video-container" className="d-flex justify-content-center">
                <button className="btn-video-play" onClick={() => this.setState({ show: true })}>
                    <img src={play} alt="play" />
                    <p>
                        Lihat video demo aplikasi
                    </p>
                </button>
                <ModalVideo
                    show={this.state.show}
                    setCloseModal={this.setCloseModal}
                />
            </div>
        )
    }

    componentFeature = () => {
        return (
            <div id="content-feature">
                <Row className="content-item row-reverse">
                    <Col>
                        {/* <img src={notulite2} alt="pic" className="content-item-img" /> */}
                        <CustomImage
                            className="content-item-img"
                            src={notulite2}
                            // placeholder={lowRes}
                            alt="Content Cover"
                        />
                    </Col>
                    <Col>
                        <div className="content-item-wrapper">
                            <h3>Mencatat Pembicaraan Rapat Secara Otomatis</h3>
                            <p>
                                Transkripsi di kala rapat berlangsung atau unggah rekaman rapat, Notulite akan langsung bekerja untuk mentranskripsi dalam Bahasa Indonesia.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="content-item">
                    <Col>
                        <div className="content-item-wrapper">
                            <h3>Sistem Manajamen yang Terintegrasi</h3>
                            <p>
                                Seluruh percakapan rapat anda dapat direkam, ditelusuri kembali dan dibagikan kepada rekan kerja anda.
                                </p>
                        </div>
                    </Col>
                    <Col >
                        {/* <img src={notulite3} alt="pic" className="content-item-img" /> */}
                        <CustomImage
                            className="content-item-img"
                            src={notulite3}
                            // placeholder={lowRes}
                            alt="Content Cover"
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    contentFiturUtama = () => {
        return (
            <div id="content-feature">
                <h2 className="text-bold ">
                    Fitur utama Notulite
                </h2>
                <Row className="content-item">
                    <Col>
                        <div className="content-paragraf-small">
                            <h4>Penyutingan Semasa Transkripsi</h4>
                            <p>
                                Revisi transkripsi dapat dilakukan tanpa harus menunggu rapat selesai.
                                </p>
                        </div>
                        <div className="content-paragraf-small">
                            <h4>Diarisasi Pembicara</h4>
                            <p>
                                Mengenali suara pembicara secara otomatis.
                                </p>
                        </div>
                        <div className="content-paragraf-small">
                            <h4>Performatan Teks</h4>
                            <p>
                                Menghasilkan transkripsi sesuai dengan ejaan yang disempurnakan.
                                </p>
                        </div>
                        <div className="content-paragraf-small">
                            <h4>Berbagai Sumber Audio</h4>
                            <p>
                                Dapat mentranskripsi rapat yang sedang berlangsung atau melalui rekaman rapat.
                                </p>
                        </div>
                    </Col>
                    <Col>
                        {/* <img src={notulite4} alt="pic" className="content-item-img top" /> */}
                        <CustomImage
                            className="content-item-img top"
                            src={notulite4}
                            // placeholder={lowRes}
                            alt="Content Cover"
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        return (
            <div id="landingpage-container">
                <NavbarMain />
                <main id="landingpage-content">
                    {this.contentTopLanding()}
                    {this.contentTopLandingVideo()}
                    {this.componentFeature()}
                    {this.contentFiturUtama()}
                </main>

                {/* footer */}
                <ContactUs />
                <Menus />
            </div>
        )
    }
}

export default Notulite