import React, { Component } from 'react'
import { Image, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// ASSETS
import "./LandingPage.scss"
import bk1 from "../../img/landing/bk1.png"
import bk2 from "../../img/landing/bk2.png"
import bk3 from "../../img/landing/bk3.png"
import bk4 from "../../img/landing/bk4.png"
import solution1 from "../../img/landing/solution1.svg"
import solution2 from "../../img/landing/solution2.svg"
import solution3 from "../../img/landing/solution3.svg"
// import arrow from "../../img/contact/arrow.svg"
import speakerleft from "../../img/landing/speakerleft.svg"
import speakerright from "../../img/landing/speakerright.svg"
import topleft from "../../img/landing/bk1-topleft.svg"
import topright from "../../img/landing/bk1-topright.svg"
import bottomleft from "../../img/landing/bk1-bottomleft.svg"
import bottomright from "../../img/landing/bk1-bottomright.svg"
import ornament1 from "../../img/landing/kotak.svg"
import ornament2 from "../../img/landing/bulat.svg"
// COMPONENT
import NavbarMain from '../../components/navbar/NavbarMain'
import CardSmall from '../../components/Cards/CardsSmall'
import Testimony from '../../components/Testimony/Testimony'
// ANIMATION
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
// GOOGLE ANALYTICS
import ReactGA from 'react-ga'
// FILES
import brosurminit from '../../files/Brosur-Minit.pdf'
import brosurnotulite from '../../files/Brosur-Notulite.pdf'
import ContactUs from '../../components/Footer/ContactUs/ContactUs'
import Menus from '../../components/Footer/Menus/Menus'

class Landing extends Component {

    constructor() {
        super()
        this.state = {
            toggle: true,
            sticky: false,
            showMenu: false,
            nomorkontak: "6282124844847"
        }
    }

    componentDidMount() {
        document.title = "Bahasa Kita | PT Bahasa Kinerja Utama"
        window.scrollTo(0,0)
    }

    scrollToElement = (name) => {
        const id = name;
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({showMenu:false})
    }

    componentTopContent = () => {
        return (
            <React.Fragment>
                <div className="content-wrapper flex-column">
                    <Fade>
                        <h1 className="content-top-header-teks large">
                            Memberi Solusi dengan teknologi
                        </h1>
                        <p className="teks-deskripsi pt-4 pb-4">
                        Cari tahu bagaimana kecerdasan artifisial dan teknologi suara memberi solusi untuk Anda.
                        {/*Cari tahu bagaimana kecerdasan artifisial dan teknologi suara dalam bahasa Indonesia memberi solusi untuk Anda.*/}
                       </p>
                    </Fade>
                </div>

                <Zoom>
                    <Button className="btn-landing blue" 
                        onClick={()=>this.scrollToElement('content-feature')}>
                        Jelajah
                    </Button>
                </Zoom>

                
                <div className="content-wrapper image-large">
                    <Zoom>
                        <Image src={bk1} alt="pic" />
                    </Zoom>
                    
                    <Image src={topleft} className="icon-hero topleft" alt="" />
                    <Image src={topright} className="icon-hero topright" alt=""  />
                    <Image src={bottomleft} className="icon-hero bottomleft" alt="" />
                    <Image src={bottomright} className="icon-hero bottomright" alt="" />
                </div>

            </React.Fragment>
        )
    }

    contentClient = () => {
        return (
            <div className="content-wrapper flex-column space-content" id="content-client">
                <Fade>
                    <h1 className="content-top-header-teks medium">
                        Klien Kami
                    </h1>
                    <p className="teks-deskripsi pt-4">
                        Mereka yang telah mendapatkan solusi dengan teknologi
                    </p>
                    {/* <div className="d-flex justify-content-center w-100">
                        <Button className="btn-landing blue" onClick={() => this.props.history.push("/")}>
                            Pemerintah
                        </Button>
                        <Button className="btn-landing dark-gray" onClick={() => this.props.history.push("/")}>
                            Swasta
                        </Button>
                    </div> */}
                    <div className="content-klien-kami">
                        <CardSmall klien="mpr.png" title="MAJELIS PERMUSYAWARATAN RAKYAT" />
                        <CardSmall klien="dpr.png" title="DEWAN PERWAKILAN RAKYAT"/>
                        <CardSmall klien="kpk.png" title="KOMISI PEMBERANTASAN KORUPSI"/>
                        <CardSmall klien="ky.png" title="KOMISI YUDISIAL"/>
                        <CardSmall klien="komnasham.png" title="KOMISI NASIONAL HAK ASASI MANUSIA"/>
                        <CardSmall klien="watimpres.png" title="DEWAN PERTIMBANGAN PRESIDEN"/>
                        <CardSmall klien="garuda.png" title="KEMENTERIAN SEKRETARIAT NEGARA"/>
                        <CardSmall klien="garuda.png" title="SEKRETARIAT KABINET"/>
                        <CardSmall klien="kemenkomarves.png" title="KEMENTERIAN KOORDINATOR BIDANG KEMARITIMAN DAN INVESTASI"/>
                        <CardSmall klien="garuda.png" title="KEMENTERIAN KOORDINATOR BIDANG PEREKONOMIAN"/>
                        <CardSmall klien="kemenhan.png" title="SEKRETARIAT JENDERAL KEMENTERIAN PERTAHANAN"/>
                        <CardSmall klien="kemenkeu.png" title="KEMENTERIAN KEUANGAN"/>
                        <CardSmall klien="kemenppn.png" title="KEMENTERIAN PERENCANAAN PEMBANGUNAN NASIONAL"/>
                        <CardSmall klien="kementan.png" title="DIREKTORAT JENDERAL PERKEBUNAN KEMENTERIAN PERTANIAN"/>
                        <CardSmall klien="kemenkeu.png" title="DIREKTORAT JENDERAL ANGGARAN KEMENTERIAN KEUANGAN"/>
                        <CardSmall klien="kemenpupr.png" title="BALAI PENERAPAN TEKNOLOGI KONTRUKSI KEMENTERIAN PUPR"/>
                        <CardSmall klien="kemenlhk.png" title="KEMENTERIAN LINGKUNGAN HIDUP DAN KEHUTANAN"/>
                        <CardSmall klien="kemenkeu.png" title="BADAN KEBIJAKAN FISKAL KEMENTERIAN KEUANGAN"/>
                        <CardSmall klien="kemenkes.png"  title="KEMENTERIAN KESEHATAN"/>
                        <CardSmall klien="kemenkeu.png" title="SEKRETARIAT PENGADILAN PAJAK KEMENTERIAN KEUANGAN"/>
                        <CardSmall klien="lps.png" title="LEMBAGA PENJAMIN SIMPANAN"/>
                        <CardSmall klien="bppt.png" title="BADAN PENGKAJIAN DAN PENERAPAN TEKNOLOGI"/>
                        <CardSmall klien="brin.png" title="BADAN RISET DAN INOVASI NASIONAL"/>
                        <CardSmall klien="tni-au.png" title="DINAS PSIKOLOGI TNI AU DAN PAM AU"/>
                        <CardSmall klien="tni-au.png" title="MARKAS BESAR TNI AU"/>
                        <CardSmall klien="tni-ad.png" title="MARKAS BESAR TNI AD"/>
                        <CardSmall klien="baharkam.png" title="BADAN PEMELIHARA KEAMANAN KEPOLISIAN NEGARA"/>
                        <CardSmall klien="tvri.png" title="TELEVISI REPUBLIK INDONESIA"/>
                        <CardSmall klien="pal.png" title="PT. PAL INDONESIA"/>
                        <CardSmall klien="dprd-deli-serdang.png" title="DPRD KABUPATEN DELI SERDANG"/>
                        <CardSmall klien="pemprov-pabar.png" title="PEMERINTAH PROVINSI PAPUA BARAT"/>
                        <CardSmall klien="pemprov-kaltim.png" title="PEMERINTAH PROVINSI KALIMANTAN TIMUR"/>
                        <CardSmall klien="pks.png" title="PARTAI KEADILAN SEJAHTERA"/>
                        <CardSmall klien="nu.png" title="NAHDLATUL ULAMA"/>
                        <CardSmall klien="bca.png" title="PT. BANK CENTRAL ASIA"/>
                        <CardSmall klien="dprd-diy.png" title="DPRD DAERAH ISTIMEWAH YOGYAKARTA"/>
                        <CardSmall klien="pemkab-sanggau.png" title="PEMERINTAH KABUPATEN SANGGAU"/>
                        <CardSmall klien="pemprov-bali.png" title="BKPSDM PEMERINTAH PROVINSI BALI"/>
                        <CardSmall klien="pn-makassar.png" title="PENGDILAN NEGERI MAKASSAR"/>
                    </div>
                </Fade>
            </div>
        )

    }

    contentSolution = () => {
        return (
            <div className="content-wrapper flex-column space-content" id="content-solution">
                <div id="ornament-1">
                    <Image src={ornament2}/>
                </div>
                <div id="ornament-2">
                    <Image src={ornament1}/>
                </div>
                <Fade>
                    <h1 className="content-top-header-teks medium white">
                        Lebih dari itu, kami punya solusinya
                    </h1>
                    <p className="teks-deskripsi white pt-4 pb-4">
                        Berikut daftar lengkap produk dan layanan kami untuk mengembangkan bisnis Anda.
                    </p>
                </Fade>
                <Fade>
                    <Row className="content-solution">
                        <Col className="content-solution-item">
                            <div className="solution-header">
                                <h4>Rapat</h4>
                                <Image src={solution1} alt=""/>
                            </div>
                            <div className="solution-teks">
                                <p>Mentranskripsikan semua rapat Anda secara otomatis</p>
                            </div>
                            <div className="solution-menu">
                                {/*<ReactGA.OutboundLink
                                    eventLabel="minit"
                                    to={brosurminit}
                                    target="_blank">
                                    <a 
                                    // href={brosurminit} 
                                    target="_blank" rel="noopener noreferrer">Minit</a>
                                </ReactGA.OutboundLink>*/}
                                <ReactGA.OutboundLink
                                    eventLabel="notulite"
                                    to={brosurnotulite}
                                target="_blank">
                                    <a // href={window.link.notula} 
                                    target="_blank" rel="noopener noreferrer">Notulite</a>
                                </ReactGA.OutboundLink>
                                {/*<ReactGA.OutboundLink
                                    eventLabel="notula"
                                    // to={brosurnotulite}
                                    target="_blank">
                                    <a href={window.link.diktein} 
                                    target="_blank" rel="noopener noreferrer">Diktein</a>
                                </ReactGA.OutboundLink>*/}
                            </div>
                        </Col>

                        <Col className="content-solution-item">
                            <div className="solution-header">
                                <h4>Gaya hidup</h4>
                                <Image src={solution2} alt=""/>
                            </div>
                            <div className="solution-teks">
                                <p>
                                    Rasakan teknologi kami untuk kehidupan sehari-hari
                                </p>
                            </div>
                            <div className="solution-menu">
                                <ReactGA.OutboundLink
                                    eventLabel="minit"
                                    to={window.link.tmanapps}
                                    target="_blank"
                                >
                                    <a target="_blank" rel="noopener noreferrer">T-Man</a>
                                </ReactGA.OutboundLink>
                                <ReactGA.OutboundLink
                                    eventLabel="minit"
                                    to={window.link.smartspeakervideo}
                                    target="_blank"
                                >
                                    <a target="_blank" rel="noopener noreferrer">Smart Speaker</a>
                                </ReactGA.OutboundLink>
                                {/* <Link to="/tman">T-Man</Link> */}
                                {/* <Link to="/smartspeaker">Smart Speaker</Link> */}
                            </div>
                        </Col>
                        <Col className="content-solution-item">
                            <div className="solution-header">
                                <h4>Layanan</h4>
                                <Image src={solution3} alt=""/>
                            </div>
                            <div className="solution-teks">
                                <p>Platform multibahasa untuk transkripsi dan translasi otomatis</p>
                            </div>
                            <div className="solution-menu">
                                    <ReactGA.OutboundLink
                                        eventLabel="diktein"
                                        to={window.link.diktein}
                                        target="_blank">
                                        Diktein
                                    </ReactGA.OutboundLink>
                                    {/*<ReactGA.OutboundLink
                                        eventLabel="tts"
                                        to={window.link.webconsole}
                                        target="_blank">
                                        Speech to Text
                                    </ReactGA.OutboundLink>*/}
                            </div>
                        </Col>
                    </Row>
                </Fade>
            </div>
        )
    }

    componentFeature = () => {
        return (
            <div id="content-feature">

                <Row className="content-item">
                    <Col>
                        <Fade>
                            <div className="content-item-wrapper">
                                <label>Solusi Platform Multibahasa</label>
                                <h3>Platform multibahasa untuk transkripsi dan translasi otomatis</h3>
                                <p>
                                Percayakan transkripsi dan translasi segala kebutuhan Anda dengan Dikte.in. Dilengkapi dengan beragam fitur kekinian lainnya!
                                </p>
                                <ReactGA.OutboundLink
                                    eventLabel="diktein"
                                    to={window.link.diktein}
                                    target="_blank"
                                >
                                    <a
                                        href={window.link.diktein}
                                        target="_blank" rel="noopener noreferrer">
                                        <Button className="btn-landing blue" id="webbklanding-learnmorewebconsole">
                                            Lanjut 
                                        </Button>
                                    </a>
                                </ReactGA.OutboundLink>
                            </div>
                        </Fade>
                    </Col>

                    <Col>
                        <Fade>
                            <Image src={bk2} alt="minit" className="content-item-img top" />
                        </Fade>
                    </Col>
                </Row>

                <Row className="content-item row-reverse">
                    <Col>
                        <Fade>
                            <Image src={bk3} alt="pic" className="content-item-img middle" />
                        </Fade>
                    </Col>
                    <Col>
                        <Fade>
                            <div className="content-item-wrapper">
                                <label>Solusi Kecerdasan Artifisial</label>
                                <h3>Teknologi ucapan ke tulisan (<i>speech to text</i>) dan tulisan ke ucapan (<i>text to speech</i>) multibahasa</h3>
                                <p>
                                    Perkaya produk dan kembangkan bisnis Anda dengan teknologi ucapan ke tulisan dan tulisan ke ucapan kami.</p>
                                <ReactGA.OutboundLink
                                    eventLabel="web-console"
                                    to={window.link.diktein}
                                    target="_blank"
                                >
                                    <a
                                        href={window.link.diktein}
                                        target="_blank" rel="noopener noreferrer">
                                        <Button className="btn-landing blue" id="webbklanding-learnmorewebconsole">
                                            Lanjut 
                                        </Button>
                                    </a>
                                </ReactGA.OutboundLink>
                            </div>
                        </Fade>
                    </Col>
                </Row>

                <Row className="content-item">
                    <Col>
                        <Fade>
                            <div className="content-item-wrapper">
                                <label>solusi otomatisasi rapat</label>
                                <h3>Mentranskripsikan semua rapat Anda secara otomatis</h3>
                                <p>
                                Notula, solusi transkripsi rapat, seminar hingga percakapan telepon Anda.
                                </p>
                                <ReactGA.OutboundLink
                                    eventLabel="notula"
                                    to={brosurnotulite}
                                    // to={window.link.brosur_notulite}
                                    target="_blank"
                                >
                                    <a
                                        // href={window.link.brosur_notulite}
                                        target="_blank" rel="noopener noreferrer">
                                        <Button className="btn-landing blue" id="webbklanding-learnmorenotulite">
                                            Brosur  
                                        </Button>
                                    </a>
                                </ReactGA.OutboundLink>
                            </div>
                        </Fade>
                    </Col>
                    <Col >
                        <Image src={speakerright} className="icon-speaker right" alt="" />
                        <Image src={speakerleft} className="icon-speaker left" alt="" />
                        <Fade>
                            <Image src={bk4} alt="pic" className="content-item-img bottom" />
                        </Fade>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        return (
            <div id="landingpage-container">

                <NavbarMain 
                    onCLick={()=>this.scrollToElement("landingpage-content")}
                />

                <main id="landingpage-content">
                    {this.componentTopContent()}
                    {this.componentFeature()}
                </main>
                
                {this.contentSolution()}
                <Testimony/>
                {this.contentClient()}

                {/* footer */}
                <>
                    <ContactUs />
                    <Menus />
                </>

            </div>
        )
    }
}

export default Landing
