import React from 'react'
import "./Cards.scss"

// Small Card with image
const CardsSmall = (props) => {
    return (
        <div className="card-container small">
            <img src={require(`../../img/partner/${props.klien}`)} alt="" />
            <p>{props.title}</p>
        </div>
    )
}

export default CardsSmall
