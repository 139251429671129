import React from 'react'
import { Link } from "react-router-dom"
import { NavDropdown } from 'react-bootstrap'
import "./Navbar.scss"
// GOOGLE ANALYTICS
import ReactGA from 'react-ga'

const DropdownMenu = (props) => {
    return (
        <NavDropdown title="Solusi Kami">
            <div className="dropdown-menu-header">
                <div className="dropdwn-menu-item">
                    <div className="solution-header">
                        <h4>Rapat</h4>
                    </div>
                    <div className="solution-menu">
                        {/*<ReactGA.OutboundLink eventLabel="minit" to={window.link.minit}
                            target="_blank" rel="noopener noreferrer">Minit</ReactGA.OutboundLink>*/}
                        {/* <ReactGA.OutboundLink eventLabel="notulite" to={window.link.notulite}
                            target="_blank" rel="noopener noreferrer">Notulite</ReactGA.OutboundLink> */}
                        <Link to="/notulite" onClick={props.handleShow}>Notulite</Link>                                            
                        {/* <ReactGA.OutboundLink eventLabel="notula" to={window.link.notula}
                            target="_blank" rel="noopener noreferrer">Notula</ReactGA.OutboundLink> */}
                    </div>
                </div>

                <div className="dropdwn-menu-item">
                    <div className="solution-header">
                        <h4>Gaya Hidup</h4>
                    </div>
                    <div className="solution-menu">
                        <Link to="/tman" onClick={props.handleShow}>T-Man</Link>
                        <Link to="/smartspeaker" onClick={props.handleShow}>Smart Speaker</Link>
                    </div>
                </div>

                <div className="dropdwn-menu-item">
                    <div className="solution-header">
                        <h4>Layanan</h4>
                    </div>
                    <div className="solution-menu">
                        <ReactGA.OutboundLink eventLabel="notulite" to={window.link.diktein}
                            target="_blank" rel="noopener noreferrer">Diktein</ReactGA.OutboundLink>
                        {/*<ReactGA.OutboundLink
                            eventLabel="tts" to={window.link.tts}
                            target="_blank" rel="noopener noreferrer">Text To Speech</ReactGA.OutboundLink>
                        <ReactGA.OutboundLink
                            eventLabel="stt" to={window.link.stt}
                        target="_blank" rel="noopener noreferrer">Speech To Text</ReactGA.OutboundLink>*/}
                    </div>
                </div>
            </div>
            <div className="dropdown-menu-footer">
                <h4>Coba solusi terbaik Kami?</h4>
                <ReactGA.OutboundLink eventLabel="notulite" to={window.link.diktein}
                            target="_blank" rel="noopener noreferrer">Diktein</ReactGA.OutboundLink>
                {/*<ReactGA.OutboundLink eventLabel="minit" to={window.link.minit}
                    target="_blank" rel="noopener noreferrer">Online Meeting</ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="web-console" to={window.link.webconsole}
                    target="_blank" rel="noopener noreferrer">API and Development</ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="notulite" to={window.link.notulite}
                    target="_blank" rel="noopener noreferrer">On Premise App</ReactGA.OutboundLink>*/}
            </div>
        </NavDropdown>
    )
}

export default DropdownMenu
