import React from 'react'
import { history } from '../../../store';

// GOOGLE ANALYTICS
import ReactGA from 'react-ga'

import { Image, Button, Row, Col } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'

// import "./ContactUs.scss"

import kontakkami from "../../../img/landing/kontakkami.png"


export class ContactUs extends React.Component {
    render() {
        return (
            <div id="content-bottom ">
                <Row className="content-item bg-gray">
                    <Col md={6}>
                        <Fade>
                            <div className="content-item-wrapper">
                                <h1>Tertarik dengan Produk kami ?</h1>
                                <Button className="btn-landing blue" onClick={() => history.push("/contact")}>
                                    Kontak Kami
                                </Button>
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6}>
                        <Fade>
                            <div className="">
                                <Image src={kontakkami} alt="pic" className="content-item-img" />
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ContactUs
