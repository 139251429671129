import React from 'react';
import { HashRouter as Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import LandingPage from './page/landingpage/LandingPage'
import NLP from './page/product/NLP';
import TMan from './page/product/TMan';
import SmartSpeaker from './page/product/SmartSpeaker';
import Contact from './page/contact/Contact';
import AboutUs from './page/about/About';
import withTracker from './withTracker';
import { Notulite } from './page/product/Notulite/Notulite';

class App extends React.Component {
  render() {
    return (
      <section>
        <Switch>
          <Route exact path="/" component={withTracker(LandingPage)} />
          <Route path="/about" component={withTracker(AboutUs)} />
          <Route path="/nlp" component={withTracker(NLP)} />
          <Route path="/tman" component={withTracker(TMan)} />
          <Route path="/smartspeaker" component={withTracker(SmartSpeaker)} />
          <Route path="/notulite" component={withTracker(Notulite)} />
          <Route path="/contact" component={withTracker(Contact)} />
        </Switch>
      </section>
    )
  }
}

export default App