import React, { Component } from 'react'
import { Image, Button, Row, Col } from 'react-bootstrap'
import "./Product.scss"

// import { Link } from 'react-router-dom'

import NavbarMain from '../../components/navbar/NavbarMain'

import landing1 from "../../img/product/tman1.svg"
import landing2 from "../../img/product/tman2.svg"
import bubble from "../../img/product/bubble.svg"
import itemmenu1 from "../../img/product/Podcast.svg"
import itemmenu2 from "../../img/product/KomikInteraktif.svg"
import itemmenu3 from "../../img/product/Narator.svg"
import itemmenu4 from "../../img/product/TTS.svg"
import kontakkami from "../../img/landing/kontakkami.png"

import Fade from 'react-reveal/Fade'
import ContactUs from '../../components/Footer/ContactUs/ContactUs'
import Menus from '../../components/Footer/Menus/Menus'

export class TMan extends Component {
    componentDidMount() {
        document.title = "Bahasa Kita | T-Man"
        // document.getElementById('product-content-top').focus();
        window.scrollTo(0,0)  
    }

    scrollToElement = () => {
        const yOffset = -120; 
        const element = document.getElementById("contentFeature");
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({showMenu:false})
    }

    // Content Rendered
    contentTopLanding = () => {
        return (
            <div tabIndex="0" id="product-content-top" className="product-content-wrapper">
                <div className="product-content-paragraf">
                    <h1>
                        Aplikasi untuk anak dan keluarga
                    </h1>
                    <p>
                        Berikan pengalaman baru dalam menikmati komik yang edukatif dan interaktif untuk anak Anda.
                    </p>
                    <button id="product_tman_getstarted" onClick={()=>this.scrollToElement()}>
                        Cari tahu
                    </button>
                </div>
                <div className="product-content-rectangle"></div>
                <div className="product-content-image-wrapper medium">
                    <Image src={landing1} />
                </div>
                <div className="product-content-bubble">
                    <Image src={bubble} />
                </div>
            </div>
        )
    }

    contentFeature = () => {
        return (                                            
                <div id="product-content-top" className="product-content-wrapper">
                   {/* <div className="product-content-rectangle-4"></div> */}
                    <div className="product-content-image-wrapper" id="contentFeature">
                        <Image src={landing2}/>                        
                    </div>
                    <div className="product-content-paragraf medium">
                        <h2>
                            Komik digital yang interaktif</h2>
                        <p className="s">
                            Komik kami memiliki fitur aksi yang memberikan pengalaman baru dalam membaca dan merangsang imajinasi.
                        </p>
                    </div>
                    
                </div>
        )
    }
    
    contentBottomLanding = () => {
        return (
            <div id="content-product-menu">
                <h2>
                    Fitur T-Man
                </h2>
                <h5>
                    T-Man memiliki fitur fitur menarik untuk mendukung edukasi anak
                </h5>
                <div className="content-product-menu">
                    <div className="content-product-menu-item">
                        <Image src={itemmenu1} />
                        <h3>Podcast</h3>
                        <p>
                            Sajian konten suara untuk orangtua dengan berbagai topik yang tidak boleh Anda lewatkan
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu2} />
                        <h3>Komik Interaktif</h3>
                        <p>
                            Komik dengan fitur interaksi, memberikan pengalaman baru.
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu3} />
                        <h3>Komik Bersuara</h3>
                        <p>
                            Tidak hanya dibaca, komik kami juga mengeluarkan suara! Segera.
                        </p>
                    </div>
                    <div className="content-product-menu-item">
                        <Image src={itemmenu4} />
                        <h3>Konversi tulisan ke suara</h3>
                        <p>
                            Hanya dengan menulis, kamu dapat membuat podcast di Tman.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarMain />
                <div id="landing-page-product">
                    <Fade>{this.contentTopLanding()}</Fade>
                    <Fade>{this.contentFeature()}</Fade>
                </div>
                <div className="landing-bottom-page-product">
                    <Fade>{this.contentBottomLanding()}</Fade>
                </div>
                
                {/* footer */}
                <ContactUs />
                <Menus />

            </React.Fragment>
        )
    }
}

export default TMan
