import React, { Component } from 'react'
import { Form, Button, Row, Col, Image } from 'react-bootstrap'
// COMPONENT
import NavbarMain from '../../components/navbar/NavbarMain'
// ASSETS
import "./Contact.scss"
import "../landingpage/LandingPage.scss"
import phone from "../../img/contact/phone.svg"
import whatsapp from "../../img/landing/whatsapp.svg"
import room from "../../img/contact/room.svg"
import dot from "../../img/contact/dot.svg"
import box from "../../img/contact/box.svg"
import arrow from "../../img/contact/arrow.svg"
import wa from "../../img/contact/wa.svg"
import email from "../../img/contact/mail.svg"

// ANIMATION
import Fade from 'react-reveal/Fade'
// GOOGLE ANALYTICS
import ReactGA from 'react-ga'
import ContactUs from '../../components/Footer/ContactUs/ContactUs'

class Contact extends Component {

    componentDidMount() {
        document.title = "Bahasa Kita | Kontak Kami";
        window.scrollTo(0,0)
    }

    componentContactInfo = () => {
        return (
            <div id="contact-info">
                <h2>Hubungi Kami</h2>
                <div>
                    <a href="https://api.whatsapp.com/send?phone=6282227711137" target="_blank" rel="noopener noreferrer">
                        <Button className="btn-landing dark-gray mb-3">
                            <Image src={whatsapp} />
                            +(62) 822 2771 1137
                        </Button>
                    </a>
                    <a href="tel:+6282124844847" target="_blank" rel="noopener noreferrer">
                        <Button className="btn-landing blue mb-3">
                            <Image src={phone} />
                            +(62) 821 2484 4847
                        </Button>
                    </a>
                </div>
            </div>
        )
    }

    componentContactTop = () => {
        return (
            <div className="text-center" id="contact-top">
                <Fade>
                    <h1 className="content-top-header-teks large">
                        Sudah siap mendapatkan solusi dengan teknologi ?
                    </h1>
                    <p className="teks-deskripsi pt-4 pb-4">
                        Kami siap melayani Anda 24/7
                    </p>
                </Fade>
            </div>
        )
    }

    componentContuctUsForm = () => {
        return (
            <Row id="contact-form">
                <Col md={4}>
                    <Fade>
                        <h4>Hubungi Kami</h4>
                        <p>
                            Hubungi kami untuk informasi lebih lanjut, pertanyaan dan kebutuhan demo
                            silakan mengisi formulir berikut dan kami akan memberikan jawaban secepatnya.
                        </p>
                    </Fade>
                </Col>
                <Col>
                    <Fade>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Control placeholder="Nama" />
                                </Col>
                                <Col>
                                    <Form.Control placeholder="Email" />
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Control as="textarea" rows="5" placeholder="Message" />
                            </Form.Group>
                            <Button 
                                style={{
                                    padding: "10px 15px",
                                    borderRadius : "20px"
                                }}>
                                Kirim Pesan
                            </Button>
                        </Form>
                    </Fade>
                </Col>
            </Row>
        )
    }

    componentAddress = () => {
        return (
            <div id="contact-maps">
                <iframe 
                    title="mapsbk"
                    width="100%" 
                    height="450" 
                    id="gmap_canvas" 
                    src="https://maps.google.com/maps?q=PT%20Bahasa%20Kinerja%20utama&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                    frameBorder="0" 
                    scrolling="no" 
                    marginHeight="0" 
                    marginWidth="0">
                        
                </iframe>
            </div>
        )
    }

    topContent = () => {
        return (
            <Fade>
            <Row id="contact-top">
                <Col md={6} xl={6} xs={12} className="contact-content-top">
                    <label>Kontak Kami</label>
                    <h3>
                    Kami terbuka dengan kerjasama dan penawaran dari Anda. 
                    Jangan sungkan.
                    </h3>
                    <p>
                    Jl. H. Naman No. 2A-C, Pondok Kopi <br/>Duren Sawit, Jakarta 13460<br/> (+62)21 2284 6729
                    </p>
                    <div className="button-wrapper">
                        <ReactGA.OutboundLink eventLabel="contact_whatsapp_click"
                            to="https://api.whatsapp.com/send?phone=6282124844847" target="_blank" rel="noopener noreferrer">
                            <button>
                                <img src={wa} alt="whatsapp"/>
                                Whatsapp
                            </button>
                        </ReactGA.OutboundLink>
                        {/* <ReactGA.OutboundLink eventLabel="contact_phone_click">
                            <button>
                                <img src={phone} alt="phone"/>
                                +6221 22846729
                            </button>
                        </ReactGA.OutboundLink> */}
                        <ReactGA.OutboundLink eventLabel="contact_email_click"
                            to="mailto:info@bahasakita.co.id">
                            <button>
                                <img src={email} alt="email"/>
                                Email
                            </button>
                        </ReactGA.OutboundLink>
                    </div>
                </Col>
                <Col md={6} xl={6} xs={12} className="contact-content-top-right">
                    <div className="top-img">
                        <Image src={room}/>
                    </div>
                    <div className="bubble">
                        <img src={dot} alt=""/>
                    </div>
                    <div className="box">
                        <img src={box} alt=""/>
                    </div>
                    <div className="map-info">
                        <ReactGA.OutboundLink eventLabel="contact_map-info_click" to="https://goo.gl/maps/Lc4yxLhY17V2nBdQ7" target="_blank"> Buka Peta
                        <img src={arrow} alt="map"></img> 
                        </ReactGA.OutboundLink>                        
                    </div>
                </Col>
            </Row>
            </Fade>
        )
    }

    render() {
        return (
            <div id="contact-container">
                <NavbarMain />
                {this.topContent()}

                {/* {this.componentContactTop()} */}
                {/* {this.componentContuctUsForm()} */}
                {/* {this.componentContactInfo()} */}
                {/* {this.componentAddress()} */}

                <ContactUs />
            </div>
        )
    }
}

export default Contact
