import React, { Component } from 'react'
import { Image, Button, Row, Col } from 'react-bootstrap'
// COMPONENTS
import NavbarMain from '../../components/navbar/NavbarMain'
// ASSETS
import "../landingpage/LandingPage.scss"
import "./About.scss"
import kontakkami from "../../img/landing/kontakkami.png"
import about1 from "../../img/about/about1.png"
import bubble from "../../img/about/bubble.svg"
import tinc from "../../img/about/tinc.jpg"
import invidia from "../../img/about/invidia.jpg"
import google from "../../img/about/google.jpg"
import aws from "../../img/about/aws.jpg"
import kataai from '../../img/about/kataai.png'

import Fade from 'react-reveal/Fade'
import Menus from '../../components/Footer/Menus/Menus'
import ContactUs from '../../components/Footer/ContactUs/ContactUs'

class AboutUs extends Component {

    componentDidMount() {
        document.title = "Bahasa Kita | Tentang Kami";
        window.scrollTo(0,0)
    }
    
    contentDescription = () => {
        return (
            <div className="about-content-top">
                <Fade >
                    <div className="about-content-top-teks">
                        <label>Bahasa Kita adalah</label>
                        <h3 className="content-top-header-teks">
                            Fokus pada pengenalan wicara, kecerdasan buatan dan pemrosesan data alami dalam Bahasa Indonesia.
                        </h3>
                        <p className="teks-deskripsi">
                            Sejak 2015, PT Bahasa Kita fokus pada pengembangan kecerdasan buatan, <i>machine learning</i> dan pemrosesan bahasa alami dalam suara yang menghasilkan solusi untuk berbagai aspek bisnis.
                        </p>
                    </div>
                </Fade>
                <Fade>
                    <Image src={about1} />
                </Fade>
            </div>
        )
    }

    contentVisiMisi = () => {
        return (
            <div id="about-content-visimisi">
                <Row>
                    <Col className="about-visi">
                        <Fade right>
                            <h3 className="content-top-header-teks">
                                Visi Kami
                            </h3>
                            <p className="teks-deskripsi">
                                Menjadi perusahaan termaju dan terbesar dalam pengembangan dan penyediaan produk-produk teknologi bahasa.
                            </p>
                        </Fade>
                    </Col>
                    <Image src={bubble} className="ornamen-bubble left"/>
                </Row>
                <Row>
                    <Col className="about-misi">
                        <Fade left>
                            <h1 className="content-top-header-teks">
                                Misi kami
                            </h1>
                            <p className="teks-deskripsi medium">
                                Menjalankan riset dan pengembangan produk yang inovatif untuk menghasilkan teknologi bahasa yang mutakhir dan berkualitas tinggi.
                            </p>
                            <p className="teks-deskripsi medium">
                                Mengimplementasikan teknologi bahasa untuk jati diri, kemandirian dan daya saing bangsa.
                            </p>
                            <p className="teks-deskripsi medium">
                                Menjalin kemitraan dan sinergi yang saling menguntungkan dengan berbagai pihak, baik swasta atau pun instansi pemerintahan.
                            </p>
                        </Fade>
                    </Col>
                    <Image src={bubble} className="ornamen-bubble right"/>
                </Row>
            </div>
        )
    }

    contentReward = () => {
        return (
            <div id="about-content-reward">
                <Fade>
                    <h2>
                        Bahasa Kita terpilih menjadi bagian dari
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center pt-5 pb-5">
                        <div className="img-container">
                            <Image src={aws} />
                        </div>
                        <div className="img-container">
                            <Image src={invidia} />
                        </div>
                        <div className="img-container">
                            <Image src={tinc} />
                        </div>
                        <div className="img-container">
                            <Image src={google} />
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }

    contentPartners = () => {
        return (
            <div id="about-content-reward">
                <Fade>
                    <h2>
                        Rekan Kami
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center pt-5 pb-5">
                        <div className="img-container">
                            <Image src={kataai} />
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }

    contentRecognisi = () => {
        return (
            <div id="about-content-visimisi">
                <Row>
                    <Col className="about-visi">
                        <Fade right>
                            <h3 className="content-top-header-teks">
                            Rekognisi Bahasa Kita
                            </h3>
                            <p className="teks-deskripsi">
                                <b>NOMINEE</b><br/>
                                Liputan 6, SCTV - 2016
                            </p>
                            <p className="teks-deskripsi">
                                <b>FINALIST BIG 8</b><br/>
                                BCA FinHack - 2017
                            </p>
                            <p className="teks-deskripsi">
                                <b>2nd Winner</b><br/>
                                Ministry of Transportation - 2018
                            </p>
                            <p className="teks-deskripsi">
                                <b>WINNER</b><br/>
                                XL IoT Challenge
                            </p>
                            <p className="teks-deskripsi">
                                <b>GOOGLE LAUNCHPAD</b><br/>
                                2019
                            </p>
                            <p className="teks-deskripsi">
                                <b>INCEPTION PROGRAM</b><br/>
                                Nvidia
                            </p>
                            <p className="teks-deskripsi">
                                <b>TINC.ID #4</b><br/>
                                Telkomsel - 2020
                            </p>
                        </Fade>
                    </Col>
                    <Image src={bubble} className="ornamen-bubble left"/>
                </Row>
                
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <NavbarMain />
                <div id="about-container">
                    {this.contentDescription()}
                    {this.contentVisiMisi()}
                    {this.contentReward()}
                    {this.contentRecognisi()}
                    {/* {this.contentPartners()} */}
                </div>

                {/* footer */}
                <ContactUs />
                <Menus />
                
            </React.Fragment>
        )
    }
}

export default AboutUs
